import React from 'react';
import { isMobileOnly } from "react-device-detect";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean';
import { stringTrim } from 'application/utility';
import LangLink from "components/lang/LangLink";
import { useTranslation } from 'react-i18next';
import DOMPurify from 'isomorphic-dompurify';
require("styles/common/colors.scss");
require("styles/components/home/about.scss");

import BuyLease from 'assets/images/icons/buy-and-lease-back.svg'

const HomeAboutBidaya = ({ block, full_layout, ...props }) => {
  const renderHTML = rawHTML => React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) }, className: "home-about__wrapper" });
  const data = block?.data?.body?.[0]?.value;
  const isMobile = isMobileOnly;
  const trimLength = 90;
  const { t } = useTranslation(['translation']);

  return (
    <>
    <section className='home-about'>
      <div className='container'>
          {renderHTML(data)}
      </div>
    </section>          

  </>
  );
}

export default HomeAboutBidaya;